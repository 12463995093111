import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesTabs from '../components/sections/FeaturesTabs';
import News from '../components/sections/News';
import Roadmap from '../components/sections/Roadmap';
import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta';
import FeaturesSplit from "../components/sections/FeaturesSplit";

const Home = props => {
  return (
    <React.Fragment>
      <HeroSplit hasBgColor />
    <FeaturesSplit  className="illustration-section-05" nvertMobile topDivider imageFill/>
    {/*<FeaturesTiles />*/}
    <br/>
    <br/>
    {/*<Pricing />*/}
    <Cta  invertColor split handleSubmit={props.handleSubmit} handleEmailChange={props.handleEmailChange} emailAddress={props.emailAddress}/>
    </React.Fragment>
  );
}

export default Home;