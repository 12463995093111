import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        'mt-0',
        'mb-16',
        'center-content'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">

                <div className="hero-content split-item-content center-content-mobile reveal-from-top">
                    <div className="tiles-item">
                      <div className="tiles-item-inner has-shadow">
                        <div className="pricing-item-content">
                          <Image
                              src={require('./../../assets/images/startup-rocket.svg')}
                              alt="Tidy"/>
                        </div>
                        <h2 className="mt-0 mb-16 center-content">
                          The Startup Starter Kit
                        </h2>
                        {/*<p className="mt-0 mb-32">*/}
                        {/*  Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.*/}
                        {/*</p>*/}
                        <div className="pricing-item-cta mb-8">
                          <Button tag="a" color="primary" wide href="#your-project">Get Started</Button>
                        </div>
                      </div>
                    </div>
                </div>

                <div className="hero-figure split-item-image split-item-image-fill reveal-from-bottom">
                  <div className="tiles-item">
                    <div className="tiles-item-inner has-shadow">
                      <div className="pricing-item-content">
                        <Image
                            src={require('./../../assets/images/enterprise-buildings.svg')}
                            alt="Tidy"/>
                      </div>
                      <h2 className="mt-0 mb-16 center-content">
                        The Enterprise Solution
                      </h2>
                      {/*<p className="mt-0 mb-32">*/}
                      {/*  Our landing page template works on all devices, so you only have to set it up once, and get beautiful results forever.*/}
                      {/*</p>*/}
                      <div className="pricing-item-cta mb-8">
                        <Button tag="a" color="secondary" wide href="#your-project">Get Started</Button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;