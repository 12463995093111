import React, {useState, useEffect} from 'react';
import { withRouter, Switch, Redirect } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import firebase from "firebase";

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';
import ThanksPage from "./views/ThanksPage";
import LayoutThanks from "./layouts/LayoutThanks";


const config = {
  apiKey: 'AIzaSyDLN7QfuSOmCN3wvAFYk9QJWfXkCJVrZPY',
  projectId: "nucleate-ai",
};

const local = window.location.href.includes("localhost");
const api = (local) ?
  "http://localhost:5001/nucleate-ai/us-central1/api/" :
  "https://us-central1-nucleate-ai.cloudfunctions.net/api"; // Potential for errors here! Getting cors errors with slash. Things seem to work without one.

// set up mock firestore if running locally
if (local) {
  console.log('setting up mock firebase...')
  // fetch([api, "setupMock"].join('/'))
}

firebase.initializeApp(config);


class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {email: null};
  // }

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  handleChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  submitData = (endpoint, payload) => {
    let url = null;
    if (api[api.length - 1] == '/') {
      url = new URL([api, endpoint].join(''));
    } else {
      url = new URL([api, endpoint].join('/'));
    }
    console.log('submitting data');
    const body = {
      payload: payload,
    };
    const params = {
      method: 'POST',
      body: JSON.stringify(body)
    };
    return fetch(url, params)
      .then(res => res.json())
      .catch(e => {
          console.log("Error at POST endpoint: ", endpoint, e);
          return e
        }
      )
      .then(() => console.log("submit successful"))
  };

  // Handle fields change
  handleSubmit  = () => {
    const payload = {email: this.state["email"]};
    this.submitData("email", payload)
      .then(() => console.log("POST request sent"))
      .catch(e => {
        console.log("POST failed: ", e.message)
      })
      .then(() => console.log("POST success"));
    window.location.href = window.location.href.split("/")[0] + "thanks";
  };

  handleEmailChange = e => {
    this.handleChange("email")(e);
    console.log("change")
  };

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} handleSubmit={this.handleSubmit.bind(this)} handleEmailChange={this.handleEmailChange.bind(this)} emailAddress={this.props.email}/>
            <AppRoute exact path="/thanks" component={ThanksPage} layout={LayoutThanks} />
            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} />
          </Switch>
        )} />
    );
  }
}


export default withRouter(props => <App {...props} />);