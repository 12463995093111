import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  invertLogo,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  // const logoPath =  ;

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={require(
              invertLogo ?
                './../../../assets/images/nucleate_black_neo_sans.svg' :
                './../../../assets/images/nucleate_neo_sans.svg'
            )}
            alt="Tidy"
            width={172}
            height={32} />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;