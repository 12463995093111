import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
// import Button from "../elements/Button";

// import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";



const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = props => {

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    handleSubmit,
    handleEmailChange,
    emailAddress,
    ...rest
  } = props;

  const outerClasses = classNames(
    'cta section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...rest}
      className={outerClasses}
    >
      <div className="container" id="your-project">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Let's discuss your project.
            </h3>
          </div>
          <div className="cta-action">
            <TextField
              className=""
              fullWidth={"false"}
              // key={uuid()}
              key="asdf"
              // label="Your email.."
              helperText="    "
              placeholder="Your email.."
              onChange={handleEmailChange}
              defaultValue={emailAddress}
              // error=this.soundcloudErrors
              variant="filled"
              inputProps={{disableUnderline: true}}
              style={{backgroundColor: "white"}}
            />
            <Button
              onClick={handleSubmit}
              fullWidth={true}
              border={0}
              borderRadius={3}
              boxShadow='0 3px 5px 2px rgba(255, 105, 135, .3)'
              height={50}
              padding='0 30px'
              float='left'
              marginTop={20}

              style={{color: "white", backgroundColor: "#2ECBA0"}}
            >Get Started</Button>

            <br/>

            {/*<form onSubmit={handleSubmit}>*/}
            {/*  <div className="mb-24">*/}
            {/*    <Input*/}
            {/*      type="email"*/}
            {/*      label="This is a label"*/}
            {/*      placeholder="Your email.."*/}
            {/*      formGroup="desktop"*/}
            {/*      handleChange={handleEmailChange}*/}
            {/*      value={emailAddress}*/}
            {/*      labelHidden*/}
            {/*    >*/}
            {/*      <Button tag="button" type="submit" color="primary" onClick={handleSubmit}>Get Started</Button>*/}
            {/*    </Input>*/}
            {/*  </div>*/}
            {/*</form>*/}

            {/*<Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your email">*/}
            {/*  <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <path id="email-submit-button" d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />*/}
            {/*  </svg>*/}
            {/*</Input>*/}
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;