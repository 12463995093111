import React from 'react';
import SectionHeader from '../components/sections/partials/SectionHeader';
import classNames from "classnames";

class Secondary extends React.Component {

  state = {
    demoModalActive: false
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Engage Your Visitors with a beautiful template',
      paragraph: ''
    };

    return (
      <React.Fragment>
        <section
          {...props}
          className={outerClasses}
        >
          <div className="container">
            <div className={innerClasses}>
              <SectionHeader data={sectionHeader} tag="h1" className="center-content invert-color" />
              <div className={tilesClasses}>
                <div className="reveal-from-bottom center-content" data-reveal-container=".tiles-wrap">
                  <h1>Thank you.</h1>
                  <h1>A team member will reach out shortly</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}


export default Secondary;